import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataMasterService } from 'src/app/services/data-master.service';
import { Moderator } from 'src/app/classes/moderator.class';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-table-moderators',
  templateUrl: './table-moderators.component.html',
  styleUrls: ['./table-moderators.component.scss']
})
export class TableModeratorsComponent implements OnInit {

  @Input() userType : string;

  @Input() order:string;
  @Input() orderM:string;
  displayedColumns = [];



  moderatorDataSource: MatTableDataSource<Moderator>;
  moderators: Moderator[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paddingColumnsResponsive: boolean;
  constructor(public dataService : DataMasterService ) {
    this.moderatorDataSource = new MatTableDataSource(this.moderators);
    this.loadModerators();
  }

  ngOnInit(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.paddingColumnsResponsive = true; 
    }else{
      this.paddingColumnsResponsive = false; 
    }
    //this.moderatorDataSource.paginator = this.paginator;
    this.moderatorDataSource.sort = this.sort;

    this.displayedColumns = [
      'moderator_name',
      'moderator_institution',
      'moderator_location',
      'moderator_email',
      'moderator_phonenumber',
      'moderator_id',
      'action'
    ];
  }

  loadModerators() {
    this.dataService.getAllModerators().subscribe(respuesta => {
      if (!respuesta[0]) {
        console.error(respuesta);
        this.dataService.showMessage('Error inesperado',
          'Ha ocurrido un error inesperado en la base de datos.',
          'error');
        return;
      }

      if (respuesta[1] == "no result") {
      } else {
        console.log(respuesta);
        this.moderators = respuesta[1];
        this.moderatorDataSource.data = this.moderators;
      }
    });
  }

  deleteModerator(moderator_id : number){
    this.dataService.showMessage({
      title: `¿Esta seguro de eliminar este coordinador?`,
      text: "ESTA ACCION NO SE PUEDE DESHACER",
      icon: 'warning',  
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.dataService.deleteModerator(moderator_id).subscribe(responseDelete=>{
          console.log(responseDelete);
          if (responseDelete[0] == true) {
            this.dataService.showMessage('Coordinador eliminado', '', 'success');
            this.dataService.getAllModerators().subscribe(respuesta => {
                this.moderators = respuesta[1];
                this.moderatorDataSource.data = this.moderators;
            });
          } else {
            this.dataService.showMessage('No se pudo eliminar el coordinador', '', 'error');
          }
        });
      }
    });
  }
  //sort moderators
  sortData(sort: MatSort) {
    const data = this.moderators;
    this.moderators = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'moderator_name':
          return compare(a.moderator_name.toUpperCase(), b.moderator_name.toUpperCase(), isAsc);
        case 'moderator_institution':
          return compare(a.moderator_institution.toUpperCase(), b.moderator_institution.toUpperCase(), isAsc);
        case 'moderator_location':
          return compare(a.moderator_location.toUpperCase(), b.moderator_location.toUpperCase(), isAsc);
        case 'moderator_email':
          return compare(a.moderator_email.toUpperCase(), b.moderator_email.toUpperCase(), isAsc);
        case 'moderator_phonenumber':
          return compare(a.moderator_phonenumber, b.moderator_phonenumber, isAsc);
        case 'moderator_id':
          return compare(a.moderator_id, b.moderator_id, isAsc);
        default:
          return 0;
      }
    });
    this.moderatorDataSource.data = this.moderators;
  }
  
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}




