
<mat-card>
  <mat-card-header>
    <mat-card-title>
<h1>Manual de instrucciones</h1> </mat-card-title>
  </mat-card-header>
  </mat-card>
 <mat-card>
<mat-card-content class="lateral">
  <p style="font-weight: 400; text-align: center;"><span style="text-decoration: underline;"><strong>Perfumesfindecurso</strong></span></p>
<p style="font-weight: 400; text-align: center;"><span style="text-decoration: underline;">Manual del usuario del alumno</span></p>
<p style="font-weight: 400;"> </p>
<p style="font-weight: 400; text-align: justify;">NOTA IMPORTANTE SOBRE LA VERSIÓN DE LA APLICACIÓN:</p>
<p style="font-weight: 400; text-align: justify;">Está disponible una aplicación de escritorio, que funciona a través del navegador, y también disponemos apps para móvil, tanto para Android como para IOS.</p>
<p style="font-weight: 400; text-align: justify;">En el caso de querer utilizar el dispositivo móvil, recomendamos encarecidamente que se utilice la versión de móvil pertinente, ya sea Android o IOS.</p>
<p style="font-weight: 400; text-align: justify;"><u>Desaconsejamos el uso de la versión escritorio desde un dispositivo móvil</u> ya que, aun y habiéndose previsto su uso, las condiciones de accesibilidad son menos prácticas que accediendo desde un ordenador personal.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Para empezar nos dirigiremos a esta URL:</p>
<p style="font-weight: 400; text-align: justify;"><a href="https://www.perfumesfindecurso.es/aplicacion/options">https://www.perfumesfindecurso.es/aplicacion/options</a></p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Aquí tendremos la posibilidad de seguir utilizando la version de escriotrio / web, o si lo preferimos podremos también descargar la version para nuestro dispositivo móvil o Tablet.</p>
<p style="font-weight: 400; text-align: justify;">Las opciones, tanto en version escritorio como en las versiones móviles, son las siguientes:</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;">
<li style="font-weight: 400;">Registro del alumno</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Lo primero que debemos hacer para empezar es registrarnos en la aplicación, con lo cual obtendremos las credenciales (usuario y contraseña) para poder acceder al sistema y crear y modificar nuestros pedidos.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Para poder registrarnos, el coordinador o tutor, nos tiene que haber facilitado una clave única de 7 dígitos.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Cuando accedemos al registro del alumno, lo primero que nos pedirá será esta clave.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Si la clave es correcta, entonces nos permitirá introducir todos los datos necesarios para el registro.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Es importante rellenar bien el formulario de registro.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Lo primero será escoger el curso de la lista desplegable, que será el curso que el coordinador haya creado previamente. Puede que nuestro coordinador haya solicitado el alta de mas de un curso, asi que debemos tener cuidado de escoger el grupo al que pertenecemos, para registrarnos en ese grupo.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Los requisitos para la contraseña son que debe contener como mínimo 1 letra mayúscula, 1 letra minúscula y 1 numero, y la longitud mínima es de 8 dígitos.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Si se desea se pueden utilizar símbolos.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Una vez aceptados los términos y condiciones, podemos hacer clic en registrar y si hemos introducido los datos de forma correcta, se creará el usuario y acto seguido se enviara un email al alumno confirmándole sus credenciales.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">A partir de aquí ya podemos acceder al panel de control del alumno, a través del menú de principal de la aplicación, ya sea de escritorio o de móvil.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;" start="2">
<li style="font-weight: 400;">Login y acceso al sistema</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Para acceder al sistema como alumno, deberemos hacer clic en la opción “Inicio alumno” del menú principal. Tras introducir nuestras credenciales accederemos a la pantalla de inicio, donde se nos muestra información general acerca de las ventas y beneficios obtenidos por nuestros pedidos ya cerrados.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Además, en la parte superior se nos mostrará en color rojo la fecha en que el coordinador tiene previsto tramitar vuestros pedidos.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Debemos tenerla en cuenta, porque llegada esa fecha, el coordinador tramitara los pedidos y ya no podréis realizar ningun cambio.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Tanto para realizar un nuevo pedido, como para modificar el pedido que tengamos en curso, accederemos a la opción “Realizar pedido” del menú.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;" start="3">
<li style="font-weight: 400;">Realizar pedido</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">En el caso de disponer ya productos en la cesta, el sistema siempre nos muestra un mensaje informativo de que hay un pedido pendiente de validación por parte del profesor.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Este mensaje es habitual y se mostrara a menudo para recordarnos que nuestro pedido sigue abierto para seguir incrementándolo y  modificándolo, pues aún no ha sido validado/cerrado por el profesor.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Podréis añadir productos, quitarlos o modificar sus cantidades. Recordad siempre guardar los cambios mediante el botón “Guardar cambios”.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">El funcionamiento habitual, es que el coordinador tramitara vuestros pedidos llegada la fecha indicada y que aparece en el apartado “inicio”, como hemos indicado más arriba.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Cuando el coordinador haya validado vuestro pedido, este ya se encontrará en proceso de preparación y envío junto con otros pedidos de otros alumnos, y veréis que vuestra cesta aparece vacía de nuevo.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">El coordinador os avisará llegado el momento para acordar la entrega de vuestros productos.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Previo acuerdo con el coordinador, también podréis empezar a confeccionar un nuevo pedido.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;" start="4">
<li style="font-weight: 400;">Historial</li>
</ol>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">En este apartado podemos consultar los últimos pedidos que han sido validados y cursados por el coordinador.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;" start="5">
<li style="font-weight: 400;">Cambio de contraseña</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Como su nombre indica, esta opción nos permite cambiar nuestra contraseña.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;" start="6">
<li style="font-weight: 400;">Cerrar sesión</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Podemos cerrar la sesión mediante este botón. Transcurridos 8 minutos de inactividad, la sesión también se cerrará automáticamente.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Para cualquier incidencia o consulta con respecto a la aplicación, podéis dirigiros a <a href="mailto:pedidosweb@perfumesfindecurso.es">pedidosweb@perfumesfindecurso.es</a>. Se intentará solucionar vuestro problema o consulta con la mayor rapidez.</p>

  </mat-card-content>
</mat-card>
