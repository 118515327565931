import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { LoginComponent } from './components/login/login.component';
import { OptionsComponent } from './components/options/options.component';
import { RegisterComponent } from './components/register/register.component';
import { HomeComponent } from './components/home/home.component';
import { TutorialComponent } from './components/user/tutorial/tutorial.component';
import { TutorialmodComponent } from './components/mod/tutorialmod/tutorialmod.component';
import { OrderComponent } from './components/order/order.component';
import { RecordComponent } from './components/user/record/record.component';
import { CatalogueComponent } from './components/user/catalogue/catalogue.component';
import { AdminPasswordComponent } from './components/user/admin-password/admin-password.component';
import { TableProductComponent } from './components/tables/table-product/table-product.component';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';
import { TableOrderComponent } from './components/tables/table-order/table-order.component';
import { AdminModeratorsComponent } from './components/admin/admin-moderators/admin-moderators.component';
import { ModDetailComponent } from './components/admin/mod-detail/mod-detail.component';
import { AdminCatalogueComponent } from './components/admin/admin-catalogue/admin-catalogue.component';
import { AdminAccessComponent } from './components/admin/admin-access/admin-access.component';
import { TableModeratorsComponent } from './components/tables/table-moderators/table-moderators.component';
import { AdminPurchasesComponent } from './components/admin/admin-purchases/admin-purchases.component';
import { ProductDetailComponent } from './components/admin/product-detail/product-detail.component';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { ShoppingHistoryComponent } from './components/mod/shopping-history/shopping-history.component';
import { AdminOrdersComponent } from './components/mod/admin-orders/admin-orders.component';
import { TableCourseComponent } from './components/tables/table-course/table-course.component';
import { TableSalesComponent } from './components/tables/table-sales/table-sales.component';
import { TableUsersComponent } from './components/tables/table-users/table-users.component';
import { SalesDetailComponent } from './components/mod/sales-detail/sales-detail.component';
import { UserDetailComponent } from './components/mod/user-detail/user-detail.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import  {  PdfViewerModule  }  from  'ng2-pdf-viewer';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterModComponent } from './components/admin/register-mod/register-mod.component';
import { RegisterUserComponent } from './components/mod/register-user/register-user.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CatalogueDetailComponent } from './components/catalogue-detail/catalogue-detail.component';
import { ContactComponent } from './components/contact/contact.component';
import { SalesComponent } from './components/sales/sales.component';
import { AdminCategorysComponent } from './components/admin/admin-categorys/admin-categorys.component';
import { TableCategorysComponent } from './components/tables/table-categorys/table-categorys.component';
import { EditCategoryComponent } from './components/admin/edit-category/edit-category.component';
registerLocaleData(localeEs);

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { AdminContactComponent } from './components/admin/admin-contact/admin-contact.component';
import { ExcelDumpComponent } from './components/admin/excel-dump/excel-dump.component';
import { TableExcelComponent } from './components/tables/table-excel/table-excel.component';
import { AdminOrderDateComponent } from './components/mod/admin-order-date/admin-order-date.component';
import { InfoComponent } from './components/info/info.component';
import { AdminWebStatusComponent } from './components/admin/admin-web-status/admin-web-status.component';
import { AdminUserDetailsComponent } from './components/admin/admin-user-details/admin-user-details.component';
import { TableLogComponent } from './components/tables/table-log/table-log.component';
import { AdminLogComponent } from './components/admin/admin-log/admin-log.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    OptionsComponent,
    RegisterComponent,
    HomeComponent,
    TutorialComponent,
    TutorialmodComponent,
    OrderComponent,
    RecordComponent,
    CatalogueComponent,
    AdminPasswordComponent,
    TableProductComponent,
    OrderDetailComponent,
    TableOrderComponent,
    AdminUsersComponent,
    ShoppingHistoryComponent,
    AdminOrdersComponent,
    TableCourseComponent,
    TableSalesComponent,
    TableUsersComponent,
    SalesDetailComponent,
    AdminModeratorsComponent,
    ModDetailComponent,
    AdminCatalogueComponent,
    AdminAccessComponent,
    TableModeratorsComponent,
    AdminPurchasesComponent,
    ProductDetailComponent,
    UserDetailComponent,
    RegisterModComponent,
    RegisterUserComponent,
    CatalogueDetailComponent,
    ContactComponent,
    SalesComponent,
    AdminCategorysComponent,
    TableCategorysComponent,
    EditCategoryComponent,
    AdminContactComponent,
    ExcelDumpComponent,
    TableExcelComponent,
    AdminOrderDateComponent,
    InfoComponent,
    AdminWebStatusComponent,
    AdminUserDetailsComponent,
    TableLogComponent,
    AdminLogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatStepperModule,
    MatDividerModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCheckboxModule,
    MatTooltipModule,
    NgbModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    PdfViewerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgbTooltipModule
  ],
  providers: [{
    provide: LOCALE_ID,
    useValue: 'es'
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
