import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { CategoryGroup } from 'src/app/services/data-master.service';
import Swal from 'sweetalert2';
import { DataMasterService } from 'src/app/services/data-master.service';

import { Product } from './../../../classes/product.class';
import { Requestdetails } from './../../../classes/requestdetails.class';

import * as jquery from 'jquery';

declare var $;

@Component({
  selector: 'app-table-product',
  templateUrl: './table-product.component.html',
  styleUrls: ['./table-product.component.scss']
})
export class TableProductComponent implements OnInit, OnChanges {
  readonly DEFAULT_CONFIG : string = 'default';
  readonly ORDER_DETAIL_CONFIG : string = 'order_detail';
  readonly CART_CONFIG : string = 'cart';
  readonly MOD_CONFIG : string = 'mod';
  readonly ADMIN_CONFIG : string = 'admin';
  readonly STOCK_CONFIG : string = 'stock';
  readonly CATEGORY_CONFIG : string = 'category';

  @Input() disableButtons : boolean = false;
  @Input() productList : (Product | CategoryGroup)[] = [];
  @Input() tableConfig : string = 'user';
  @Input() previousOrder : Requestdetails[] = null;
  @Input() showImageOnHover : boolean = false;
  @Input() quantityList : number[] = [];
  @Input() previousTramited : boolean = false;

  @Output() onQuantityChange : EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() addProduct : EventEmitter<Product | CategoryGroup> = new EventEmitter<Product | CategoryGroup>();
  @Output() deleteFromCart : EventEmitter<Product | CategoryGroup> = new EventEmitter<Product | CategoryGroup>();
  @Output() deleteProduct : EventEmitter<Product | CategoryGroup> = new EventEmitter<Product | CategoryGroup>();
  @Output() cancelOrder : EventEmitter<void> = new EventEmitter<void>();
  @Output() saveOrder : EventEmitter<void> = new EventEmitter<void>();

  public showDeleteProductButton : boolean = false;
  public showDeleteFromCartButton : boolean = false;
  public showAddButton : boolean = false;
  public showQuantityInput : boolean = false;
  public showQuantityText : boolean = false;
  public showModifyButton : boolean = false;
  public showModifyOrderButton : boolean = false;
  public showSearchInput : boolean = false;

  titulo : string;

  displayedColumns = [
    'product_name',
    'product_saleprice',
    'product_sellerprice',
  ];

  groupColumns = [
    'groupHeader',
    'fill'
  ]
  productDataSource : MatTableDataSource<Product | CategoryGroup>;

  @ViewChild( MatPaginator ) paginator: MatPaginator;
  @ViewChild( MatSort ) sort: MatSort;
  paddingColumnsResponsive: boolean;

  constructor(public dataService : DataMasterService ) {
    this.productDataSource = new MatTableDataSource<Product | CategoryGroup>(this.productList);
  }

  ngOnChanges(changes: SimpleChanges) : void {
    //console.log('product lsit', changes['productList']);
    
    if (changes['productList'] != null) {
      this.productDataSource.data = this.productList;
      const previousLength = changes['productList'].previousValue != null ?
        changes['productList'].previousValue.length : 0;
      const difference = this.productList.length - previousLength;

      if (difference > 0 && changes['quantityList'] == null) {
        let zeroArray = new Array(difference);
        zeroArray = zeroArray.fill(1, 0, zeroArray.length);
        this.quantityList = this.quantityList.concat(zeroArray);
        this.onQuantityChange.emit(this.quantityList);
      }
    }

    if (changes['tableConfig'] != null) {
      this.loadConfig();
    }

    //console.log(this.productDataSource.data);
    
  }

  private loadConfig() {
    switch (this.tableConfig) {
      case this.DEFAULT_CONFIG: {
        this.loadDefaultProductList();
        break;
      }
      case this.CART_CONFIG: {
        this.loadCartProductList();
        break;
      }
      case this.ORDER_DETAIL_CONFIG: {
        this.loadOrderDetailProductList();
        break;
      }
      case this.MOD_CONFIG: {
        this.loadModProductList();
        break;
      }
      case this.ADMIN_CONFIG: {
        this.loadAdminProductList();
        break;
      }
      case this.STOCK_CONFIG: {
        this.loadStockProductList();
        break;
      }
      case this.CATEGORY_CONFIG: {
        this.loadCategoryProductList();
        break;
      }
      default: {
        break;
      }
    }
  }

  private loadStockProductList() {
    this.displayedColumns = [
      'product_name',
      'product_stock',
      'product_treshold'
    ]
  }

  private loadDefaultProductList() {
    this.titulo="Productos";

    this.displayedColumns = this.displayedColumns.concat([
      'product_profit',
      'action'
    ]);

    this.showSearchInput = true;
    this.showAddButton = true;
  }

  private loadOrderDetailProductList() {
    this.titulo="Productos"

    this.displayedColumns = this.displayedColumns.concat([
      'product_profit',
      'product_quantity',
      'product_total_sale',
      'product_total_seller',
      'product_total_profit'
    ]);    

    this.showQuantityText = true;
  }

  private loadCategoryProductList() {
    this.titulo="Productos"

    this.displayedColumns = this.displayedColumns.concat([
      'product_profit'
    ]);

    this.showQuantityText = true;
  }

  private loadCartProductList() {
    this.titulo="Carrito"

    this.displayedColumns = this.displayedColumns.concat([
      'product_total_profit',
      'product_quantity',
      'action',
      'product_total_sale'
    ]);

    this.showDeleteFromCartButton = true;
    this.showQuantityInput = true;
  }

  private loadModProductList() {
    this.titulo="Modificar Pedido";

    this.displayedColumns = this.displayedColumns.concat([
      'product_profit',
      'product_total_seller',
      'product_quantity',
      'action'
    ]);
  }

  private loadAdminProductList() {
    this.titulo="Administrar Productos";

    this.displayedColumns = this.displayedColumns.concat([
      'product_treshold',
      'product_stock',
      'action'
    ]);

    this.showDeleteProductButton = true;
    this.showModifyButton = true;
  }

  ngOnInit(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.paddingColumnsResponsive = true; 
    }else{
      this.paddingColumnsResponsive = false; 
    }
    this.productDataSource.paginator = this.paginator;
    this.productDataSource.sort = this.sort;

    this.productDataSource.filterPredicate = (data, filter) => {
      if ('category_name' in data) {
        return false;
      }

      let productData : Product = data as Product;
      return productData.product_name != null && productData.product_name.toLowerCase().trim()
        .includes(filter.toLowerCase().trim());
    };
  }

  addProductTrigger(product : Product) {
    //console.log("addProductTrigger");
    this.addProduct.emit(product);
  }

  deleteFromCartTrigger(product : Product) {
    //console.log("deleteFromCartTrigger");
    const index = this.productList.indexOf(product);
    this.quantityList.splice(index, 1);

    this.onQuantityChange.emit(this.quantityList);
    this.deleteFromCart.emit(product);
  }

  deleteProductTrigger(product : Product) {
    //console.log("deleteProductTrigger");
    this.deleteProduct.emit(product);
  }

  saveOrderTrigger() {
    //console.log("saveOrderTrigger");
    this.saveOrder.emit();
  }

  cancelOrderTrigger() {
    this.dataService.showMessage({
      title: `¿Esta seguro de eliminar este pedido?`,
      text: "ESTA ACCION NO SE PUEDE DESHACER",
      icon: 'warning',  
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
    //console.log("cancelOrderTrigger");
    this.cancelOrder.emit();
  }
    });
  }


  showImage(event, product) {
    //console.log("showImage");
    if (this.showImageOnHover) {
      $('#productImage').show();
      $('#productImage').css('background-image',`url(${product.product_image})`);
      $('#productImage').css('top', `${event.clientY + 3}px`);
      $('#productImage').css('left', `${event.clientX + 3}px`);
    }
  }

  relocateImage(event, product) {
    //console.log("relocateImage");
    if (this.showImageOnHover) {
      $('#productImage').css('top', `${event.clientY + 3}px`);
      $('#productImage').css('left',`${event.clientX + 3}px`);
    }
  }

  hideImage(event, product) {
    //console.log("hideImage");
    if (this.showImageOnHover) {
      $('#productImage').hide();
      $('#productImage').css('top', `${event.clientY + 3}px`);
      $('#productImage').css('left', `${event.clientX + 3}px`);
    }
  }

  quantityModify() {
    this.onQuantityChange.emit(this.quantityList);
  }

  getTotalProfit() : number {
    const profit_array = this.productList
      .filter(item => !('category_name' in item))
      .map(item => (item as Product).product_sellerprice - (item as Product).product_saleprice);
    let total_profit_array = [];
    for (let index = 0; index < profit_array.length; index++) {
      total_profit_array.push(profit_array[index] * this.quantityList[index]);
    }

    return total_profit_array.reduce((total, previous) => total + previous);
  }

  getTotalSalePrice() : number {
    const sale_price_array = this.productList
      .filter(item => !('category_name' in item))
      .map(item => (item as Product).product_saleprice);
    let total_sale_price_array = [];
    for (let index = 0; index < sale_price_array.length; index++) {
      total_sale_price_array.push(sale_price_array[index] * this.quantityList[index]);
    }

    return total_sale_price_array.reduce((total, previous) => total + previous);
  }

  getTotalQuantity() : number {

    return this.quantityList.map(item => typeof item == 'string' ? parseInt(item, 10) : item).reduce((total, previous) => total + previous);
  }

  filter(event) : void {
    this.productDataSource.filter = event.target.value.trim().toLowerCase();
  }

  private validateAddQuantity(index : number, quantity : number = 1) : boolean {
    // NO HACER EN CASA
    if (this.previousOrder == null) {
      if (this.quantityList[index] + quantity <= (this.productList[index] as Product).product_quantity) {
        return true;
      }
      return false;
    }

    const product = this.productList[index] as Product;
    let requestdetail : any = this.previousOrder.filter(item => item.product_id == product.product_id);
    if (requestdetail.length > 0) {
      requestdetail = requestdetail[0];
      if (this.quantityList[index] + quantity <= +(this.productList[index] as Product).product_quantity +
        +requestdetail.requestdetails_quantity) {
        return true;
      }
      return false;
    }

    if (this.quantityList[index] + quantity <= (this.productList[index] as Product).product_quantity) {
      return true;
    }

    return false;
  }

  private validateSubstractQuantity(index : number, quantity : number = 1) : boolean {
    if (this.quantityList[index] + quantity > 2) {
      return true;
    }
    return false;
  }

  addQuantity(index : number) {
    if (this.validateAddQuantity(index)) {
      this.quantityList[index] = +this.quantityList[index] + 1;
      this.onQuantityChange.emit(this.quantityList);
    }
  }

  substractQuantity(index : number) {
    if (this.validateSubstractQuantity(index)) {
      this.quantityList[index] = +this.quantityList[index] - 1;
      this.onQuantityChange.emit(this.quantityList);
    }
  }

  keyboardChangeQuantity(event : any, index : number, product : Product) : void {

    if (event.key == '-' || event.key == '.') {
      //console.log(event.target.value);
      event.target.value = event.target.value.replace('\.', '');
      return;
    }

    if (!isNaN(event.key)) {
      let value = parseInt(event.target.value, 10);

      const detail = this.previousOrder != null ?
        this.previousOrder.filter(item => item.product_id == product.product_id) : null;
      let detailQuantity = 0;
      if (detail != null && detail.length > 0) {
        value = value - detail[0].requestdetails_quantity;
        detailQuantity = +detail[0].requestdetails_quantity;
      }

      if (!this.validateAddQuantity(index, value)) {
        //console.log(detail);

        const max = +product.product_quantity + detailQuantity;
        event.target.value =  max;
        this.quantityList[index] = parseInt(event.target.value, 10);
      }
    }
  }

  public isGroup(index, item) : boolean {    
    //console.log('category_name' in item);
    
    return 'category_name' in item;
  }

}
