<mat-card class="col-lg-6 col-md-12 col-sm-12 mx-auto fade-in proviaje-last-item">
  <mat-card-header >
    <mat-card-title >Administrar coordinadores</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form class="p-3"
          (ngSubmit)="updateModerator()"
          #modForm="ngForm">
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Nombre</mat-label>
        <input  type="text"
                matInput
                name="name"
                required
                [(ngModel)]="moderator_name">
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Apellidos</mat-label>
        <input  type="text"
                matInput
                name="lastname"
                required
                [(ngModel)]="moderator_lastname">
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Nombre de centro</mat-label>
        <input  type="text"
                matInput
                name="center"
                required
                [(ngModel)]="moderator_institution">
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Localidad</mat-label>
        <input  type="text"
                matInput
                name="location"
                required
                [(ngModel)]="moderator_location">
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Sobreprecio Venta (€)</mat-label>
        <input  type="number"
                matInput
                name="overprice"
                required
                [(ngModel)]="moderator_overprice">
      </mat-form-field>

      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Sobreprecio Costo (€)</mat-label>
        <input  type="number"
                matInput
                name="saleprice"
                required
                [(ngModel)]="moderator_saleprice">
      </mat-form-field>

      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Email</mat-label>
        <input  type="text"
                matInput
                name="email"
                required
                email
                [(ngModel)]="moderator_email">
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Número de telefono</mat-label>
        <input  type="text"
                matInput
                name="phone"
                required
                pattern="^[0-9]*$"
                minlength="9"
                [(ngModel)]="moderator_phonenumber">
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Contraseña</mat-label>
        <input  type="password"
                matInput
                name="password"
                [(ngModel)]="moderator_password">
      </mat-form-field>
      <mat-form-field class="col-sm-12 col-md-8 col-lg-6 m-auto">
        <mat-label>Repetir Contraseña</mat-label>
        <input  type="password"
                matInput
                name="password_r"
                [(ngModel)]="repeat_password">
      </mat-form-field>
      <button class="m-3"
              mat-flat-button
              color="primary">Modificar coordinador</button>
    </form>
  </mat-card-content>
</mat-card>
