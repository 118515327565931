import { Component, OnInit, Input, ViewChild, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { Seller } from 'src/app/classes/seller.class';
import { Grade } from 'src/app/classes/grade.class';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-table-users',
  templateUrl: './table-users.component.html',
  styleUrls: ['./table-users.component.scss']
})
export class TableUsersComponent implements OnInit {

  @Input() userType : string;
  @Input() userIdentifier : string;
  @Input() sellerList:Seller[]=[];
  @Input() grades : string;
  @Input() order : string;
  @Input() adminColumns : string;
  @Input() orderM : string;

  @Output() deleteEvent : EventEmitter<Seller> = new EventEmitter<Seller>();
  @Output() toggleStatusEvent : EventEmitter<Seller> = new EventEmitter<Seller>();

  displayedColumns = [];
  dataSource: MatTableDataSource<Seller>;

  sellerDataSource: MatTableDataSource<Seller>;
  sellers: Seller[]=[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paddingColumnsResponsive: boolean;

  constructor() {
    
    this.sellerDataSource = new MatTableDataSource(this.sellerList);
    
  }

  ngOnChanges(changes: SimpleChanges) : void {
    if (changes['sellerList'] != null) {
      this.sellerDataSource.data = this.sellerList;
      this.sellerDataSource.paginator = this.paginator;
      this.sellerDataSource.sort = this.sort;
    }
  }

  ngOnInit(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.paddingColumnsResponsive = true; 
    }else{
      this.paddingColumnsResponsive = false; 
    }
    if (this.adminColumns != undefined) {
      this.displayedColumns = ['seller_name', 'seller_lastname', 'seller_email', 'seller_phonenumber', 'grade_name', 'moderator_id', 'action'];
    }else{
      this.displayedColumns = ['seller_name', 'seller_email', 'seller_phonenumber', 'action'];
    }
  }

  filter(event) : void {
    this.sellerDataSource.filter = event.target.value.trim().toLowerCase();
  }

  deleteEventTrigger(seller : Seller) {
    this.deleteEvent.emit(seller);
  }

  toggleStatusEventTrigger(seller : Seller) {
    this.toggleStatusEvent.emit(seller);
  }
  //sort seller
  sortData(sort: MatSort) {
    const data = this.sellerList;
    this.sellerList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'seller_name':
          return compare(a.seller_name.toUpperCase(), b.seller_name.toUpperCase(), isAsc);
        case 'seller_lastname':
          return compare(a.seller_lastname.toUpperCase(), b.seller_lastname.toUpperCase(), isAsc);
        case 'seller_email':
          return compare(a.seller_email.toUpperCase(), b.seller_email.toUpperCase(), isAsc);
        case 'seller_phonenumber':
          return compare(a.seller_phonenumber, b.seller_phonenumber, isAsc);
        case 'grade_name':
          return compare(a.grade_name.toUpperCase(), b.grade_name.toUpperCase(), isAsc);
        case 'moderator_id':
          return compare(a.moderator_id.toUpperCase(), b.moderator_id.toUpperCase(), isAsc);
        default:
          return 0;
      }
    });
    this.sellerDataSource.data = this.sellerList;
  }

}
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
