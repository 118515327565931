import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { OptionsComponent } from './components/options/options.component';
import { MainComponent } from './components/main/main.component';
import { RegisterComponent } from './components/register/register.component';
import { HomeComponent } from './components/home/home.component';
import { TutorialComponent } from './components/user/tutorial/tutorial.component';
import { TutorialmodComponent } from './components/mod/tutorialmod/tutorialmod.component';
import { OrderComponent } from './components/order/order.component';
import { RecordComponent } from './components/user/record/record.component';
import { CatalogueComponent } from './components/user/catalogue/catalogue.component';
import { CatalogueDetailComponent } from './components/catalogue-detail/catalogue-detail.component';
import { AdminPasswordComponent } from './components/user/admin-password/admin-password.component';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';
import { AdminUsersComponent } from './components/admin-users/admin-users.component';
import { ShoppingHistoryComponent } from './components/mod/shopping-history/shopping-history.component';
import { AdminOrdersComponent } from './components/mod/admin-orders/admin-orders.component';
import { SalesDetailComponent } from './components/mod/sales-detail/sales-detail.component';
import { AdminModeratorsComponent } from './components/admin/admin-moderators/admin-moderators.component';
import { ModDetailComponent } from './components/admin/mod-detail/mod-detail.component';
import { AdminCatalogueComponent } from './components/admin/admin-catalogue/admin-catalogue.component';
import { AdminAccessComponent } from './components/admin/admin-access/admin-access.component';
import { AdminPurchasesComponent } from './components/admin/admin-purchases/admin-purchases.component';
import { ProductDetailComponent } from './components/admin/product-detail/product-detail.component';
import { UserDetailComponent } from './components/mod/user-detail/user-detail.component';
import { RegisterModComponent } from './components/admin/register-mod/register-mod.component';
import { RegisterUserComponent } from './components/mod/register-user/register-user.component';
import { SalesComponent } from './components/sales/sales.component';
import { ContactComponent } from './components/contact/contact.component';
import { AdminCategorysComponent } from './components/admin/admin-categorys/admin-categorys.component';
import { EditCategoryComponent } from './components/admin/edit-category/edit-category.component';
import { AdminContactComponent } from './components/admin/admin-contact/admin-contact.component';
import { ExcelDumpComponent } from './components/admin/excel-dump/excel-dump.component';
import { AdminOrderDateComponent } from './components/mod/admin-order-date/admin-order-date.component';
import { InfoComponent } from './components/info/info.component';
import { AdminWebStatusComponent } from './components/admin/admin-web-status/admin-web-status.component';
import { AdminUserDetailsComponent } from './components/admin/admin-user-details/admin-user-details.component';
import { AdminLogComponent } from './components/admin/admin-log/admin-log.component';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  { path: 'options', component: OptionsComponent },
  { path: 'info', component: InfoComponent },
  { path: 'main', component: MainComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'catalogue', component: CatalogueComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'sales', component: SalesComponent },
  { path: 'catalogue-detail/:id', component: CatalogueDetailComponent },
  { path: ':type/login', component: LoginComponent },
  { path: ':type/admin-web-status', component: AdminWebStatusComponent, canActivate: [LoginGuard] },
  { path: ':type/admin-log', component: AdminLogComponent, canActivate: [LoginGuard] },
  { path: ':type/order', component: OrderComponent, canActivate: [LoginGuard] },
  { path: ':type/order/:id', component: OrderComponent, canActivate: [LoginGuard] },
  { path: ':type/record', component: RecordComponent, canActivate: [LoginGuard] },
  { path: ':type/admin-password', component: AdminPasswordComponent, canActivate: [LoginGuard] },
  { path: ':type/order-detail/:id', component: OrderDetailComponent, canActivate: [LoginGuard] },
  { path: ':type/admin-users', component: AdminUsersComponent, canActivate: [LoginGuard] },
  { path: ':type/admin-users/:id', component: AdminUsersComponent, canActivate: [LoginGuard] },
  { path: ':type/shopping-history', component: ShoppingHistoryComponent, canActivate: [LoginGuard] },
  { path: ':type/admin-orders', component: AdminOrdersComponent, canActivate: [LoginGuard] },
  { path: ':type/sales-detail/:id', component: SalesDetailComponent, canActivate: [LoginGuard] },
  { path: ':type/home', component: HomeComponent, canActivate: [LoginGuard] },
  { path: ':type/tutorial', component: TutorialComponent, canActivate: [LoginGuard] },
  { path: ':type/tutorialmod', component: TutorialmodComponent, canActivate: [LoginGuard] },
  { path: ':type/admin-moderators', component: AdminModeratorsComponent, canActivate: [LoginGuard]  },
  { path: ':type/admin-categorys', component: AdminCategorysComponent, canActivate: [LoginGuard]  },
  { path: ':type/edit-category/:id', component: EditCategoryComponent, canActivate: [LoginGuard]  },
  { path: ':type/admin-catalogue', component: AdminCatalogueComponent, canActivate: [LoginGuard]  },
  { path: ':type/admin-access', component: AdminAccessComponent, canActivate: [LoginGuard] },
  { path: ':type/mod-detail/:id', component: ModDetailComponent, canActivate: [LoginGuard]  },
  { path: ':type/admin-purchases', component: AdminPurchasesComponent, canActivate: [LoginGuard]  },
  { path: ':type/admin-contact', component: AdminContactComponent, canActivate: [LoginGuard]  },
  { path: ':type/excel-dump', component: ExcelDumpComponent, canActivate: [LoginGuard]  },
  { path: ':type/product-detail/:id', component: ProductDetailComponent, canActivate: [LoginGuard]  },
  { path: ':type/user-detail/:id', component: UserDetailComponent, canActivate: [LoginGuard]  },
  { path: ':type/register-moderator', component: RegisterModComponent, canActivate: [LoginGuard]  },
  { path: ':type/admin-order-date', component: AdminOrderDateComponent, canActivate: [LoginGuard]  },
  { path: ':type/register-user/:grade_id', component: RegisterUserComponent, canActivate: [LoginGuard]  },
  { path: ':type/recover/:token', component: AdminPasswordComponent },
  { path: ':type/admin-user-details', component: AdminUserDetailsComponent, canActivate: [LoginGuard] },
  { path: '**', redirectTo: '/options', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
