<mat-card class="mx-auto col-lg-6 col-md-12 col-sm-12 my-5 fade-in"
          *ngIf="newProduct">
  <mat-card-header>
    <mat-card-title>
      Agregar producto
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field class="d-block w-75 p-3 mx-auto">
      <mat-label>Nombre producto</mat-label>
      <input  [(ngModel)]="product_name"
              [ngModelOptions]="{standalone: true}"
              matInput
              required>
    </mat-form-field>
    <mat-form-field class="d-block col-12 col-md-8 col-lg-6 m-auto">
      <mat-label>Seleccionar categoría</mat-label>
      <mat-select required
                  [(ngModel)]="category_id"
                  name="category">
        <mat-option *ngFor="let category of categoryData"
                    [value]="category.category_id">
          {{ category.category_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-50 p-3 mx-auto">
      <mat-label>Cantidad de producto</mat-label>
      <input  [(ngModel)]="product_quantity"
              [ngModelOptions]="{standalone: true}"
              matInput
              required
              type="number">
    </mat-form-field>
    <mat-form-field class="w-50 p-3 mx-auto">
      <mat-label>Alerta de stock</mat-label>
      <input  [(ngModel)]="product_treshold"
              [ngModelOptions]="{standalone: true}"
              matInput
              required
              type="number">
    </mat-form-field>
    <mat-form-field class="w-50 p-3 mx-auto">
      <mat-label>Precio</mat-label>
      <input  [(ngModel)]="product_saleprice"
              [ngModelOptions]="{standalone: true}"
              matInput
              required
              type="number">
    </mat-form-field>
    <mat-form-field class="w-50 p-3 mx-auto">
      <mat-label>Precio de venta</mat-label>
      <input  [(ngModel)]="product_sellerprice"
              [ngModelOptions]="{standalone: true}"
              matInput
              required
              type="number">
    </mat-form-field>
    <mat-form-field class="d-block w-75 p-3 mx-auto">
      <mat-label>Descripcion de producto</mat-label>
      <textarea matInput
                [(ngModel)]="product_description"
                [ngModelOptions]="{standalone: true}"></textarea>
    </mat-form-field>
    <!--<div class="col-12 text-center">
      <mat-checkbox class="example-margin"
                    [(ngModel)]="product_validate_generate"
                    [ngModelOptions]="{standalone: true}">Genera papeleta</mat-checkbox> 
    </div>-->
    <div class="col-12 text-center" >
      <label class="mt-3">Seleccione una imagen</label>
      {{ imageError }}
      <div class="fileInputProfileWrap ">
          <input  type="file"
                  (change)="fileChangeEvent($event)"
                  class="fileInputProfile">
          <div class="img-space">
            <img  [src]="cardImageBase64"
                  *ngIf="cardImageBase64 != null" />
            <img  src="./../aplicacion/assets/producto.png"
                  class="img-responsive"
                  *ngIf="cardImageBase64 == null">
          </div>
      </div>
      <button class="ml-1 mb-3"
              mat-flat-button color="warn"
              (click)="removeImage()"
              *ngIf="isImageSaved">Eliminar Imagen</button>
    </div>
    <div class="text-center col-12">
      <button class="ml-1"
              mat-flat-button
              color="warn"
              (click)="cancelar()">Cancelar</button>
      <button class="ml-1"
              mat-flat-button
              color="primary"
              [disabled]="disabledAddButton"
              (click)="agregarProducto()">Agregar</button>
    </div>
  </mat-card-content>
</mat-card>
<h2 class="text-center m-3" *ngIf="productList.length < 1">No hay productos cargados.</h2>

<div class="row mb-2 fade-in" *ngIf="productList.length > 0">
 
  <div class="fade-in col-md-12 col-lg-3 mx-auto mt-2 mr-2 ml-2" *ngFor="let product of productList; let i = index">
    <div  class="card bg-dark">
      <div class="card-body text-center">
        <img  [src]="product.product_image"
              class=" w-75">
        <h2>{{product.product_name}}</h2>
        <p class="card-text text-warning" >
          Precio costo: {{product.product_saleprice | currency : "EUR" : "symbol"}}</p>
        <p class="card-text">
          Precio de venta: {{product.product_sellerprice | currency : "EUR" : "symbol"}}</p>
        <p class="card-text">
          Total vendido: {{product.product_total_sold}}</p>

        <mat-divider class="bg-light"></mat-divider>
        <div  [hidden]="addStock != i">
          <p class="card-text mt-1 text-warning" >Stock actual: {{product.product_quantity}}</p>
          <mat-form-field class="w-100" >
            <mat-label class="text-light">Ingrese stock a sumar</mat-label>
            <input  type="number"
                    [(ngModel)]="add_product_stock"
                    [ngModelOptions]="{standalone: true}"
                    matInput>
          </mat-form-field>
          <button mat-raised-button
                  color="warn"
                  class="mt-2 d-block mx-auto"
                  (click)="updateStock(product.product_quantity,product.product_id)" >Actualizar stock</button>
          <button class="mt-2"
                  mat-flat-button
                  color="warn"
                  (click)="hideEditStock()">Cancelar</button>
        </div>

        <button mat-raised-button
                color="primary"
                class="mt-2 d-block mx-auto"
                (click)="showEditStock(i)" >Aumentar stock</button>
        <button [routerLink]="['/' + typeAdmin, 'product-detail', product.product_id]"
                mat-raised-button color="primary"
                class="mt-2 d-block mx-auto" >Modificar</button>
        <!--<a href="#" class="btn btn-primary">Agregar al carrito</a>-->
      </div>
    </div>
  </div>
  
</div>

<div class="col-12 text-right mb-custom">
  <button *ngIf="!newProduct"
      mat-fab
      color="primary"
      class="m-5 add-button"
      (click)="agregar()"><mat-icon>add</mat-icon>
  </button>
</div>


