import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DataMasterService } from 'src/app/services/data-master.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-register-mod',
  templateUrl: './register-mod.component.html',
  styleUrls: ['./register-mod.component.scss']
})
export class RegisterModComponent implements OnInit {
  moderator_name:string;
  moderator_password:string;
  repeat_password:string;
  moderator_lastname:string;
  moderator_email:string;
  moderator_phonenumber:string;
  moderator_institution:string;
  moderator_location:string;
  moderator_overprice:number;
  moderator_saleprice:number;

  registerFormGroup : FormGroup;

  @ViewChild('registerForm') registerForm : FormGroup;

  constructor(private dataService : DataMasterService, private router : Router, private _formBuilder : FormBuilder) {
    this.moderator_overprice = 0;
    this.moderator_saleprice = 0;

  }

  // variable
  show_button: Boolean = false;
  show_eye: Boolean = false;

  //Function
  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }

  // variable
  show_button1: Boolean = false;
  show_eye1: Boolean = false;
  
  //Function
  showPassword1() {
    this.show_button1 = !this.show_button1;
    this.show_eye1 = !this.show_eye1;
  }
  
  ngOnInit(): void {
    this.registerFormGroup = this._formBuilder.group({
      moderator_password: ['', [Validators.required, Validators.minLength(8)]],
      moderator_password_repeat: ['', [Validators.required, Validators.minLength(8)]]
    })
  }
  regModerator() {
    if (this.registerForm.invalid) {
      this.dataService.showMessage('Campos invalidos', 'Por favor, rellene de forma valida los campos marcados.', 'error');
      return;
    }

    if (this.moderator_password.length <= 0 || this.moderator_password != this.repeat_password) {
      this.dataService.showMessage('Las contraseñas no coinciden', '', 'error');
    } else {
      this.dataService.regModerator(this.moderator_name,this.moderator_password,
      this.moderator_lastname,this.moderator_email,this.moderator_phonenumber,
      this.moderator_institution, this.moderator_overprice, this.moderator_location, this.moderator_saleprice).subscribe(responseRegisterMod => {
        console.log(responseRegisterMod);
        if(responseRegisterMod[0]== true){
          this.dataService.showMessage('Coordinador registrado', '', 'success');
          this.dataService.insertLog(null, null, this.dataService.getUserId(), "Coordinador registrado correctamente").subscribe(responseLog=>{
            console.log(responseLog);
          });
          this.router.navigate(['/' + this.dataService.getUserType(), 'admin-moderators']);
        }else{
          if (responseRegisterMod[1] == 'el correo electronico que desea colocar ya esta en uso') {
            this.dataService.showMessage('El correo que ha introducido ya esta en uso', '', 'error');
            this.dataService.insertLog(null, null, this.dataService.getUserId(), "El correo que ha introducido ya esta en uso").subscribe(responseLog=>{
              console.log(responseLog);
            });
            return;
          }
          this.dataService.showMessage('No se pudo registrar el coordinador', '', 'error');
          this.dataService.insertLog(null, null, this.dataService.getUserId(), "No se pudo registrar el coordinador").subscribe(responseLog=>{
            console.log(responseLog);
          });
        }
      });

    }
  }

}
