import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataMasterService, USER_TYPE_MOD, USER_TYPE_ADMIN } from 'src/app/services/data-master.service';
import { Grade } from 'src/app/classes/grade.class';
import { Seller } from 'src/app/classes/seller.class';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {
  userType : string;
  grade_id : string;
  grade : Grade[] = [];
  sellerList : Seller[] = [];
  showRegisterCourse : boolean = false;
  grade_name : string;
  moderator_id : number;
  moderator_institution : string;

  constructor(public dataService : DataMasterService, private route : ActivatedRoute) {
    this.dataService.setFooter("fixed");
    this.userType = this.dataService.getUserType();
    this.route.params.subscribe(params => {
      if (params['type'] == USER_TYPE_ADMIN) {
        this.moderator_id = params['id'];
        this.dataService.getModerator(this.moderator_id).subscribe(responseModerator => {
          this.moderator_institution = responseModerator[1][0].moderator_institution;
        });
      } else if (params['type'] == USER_TYPE_MOD) {
        this.moderator_id = parseFloat(this.dataService.getUserId());
      }

      this.loadGradeList();
    });
  }

  loadGradeList() {
    this.dataService.getGrade(this.moderator_id).subscribe(response => {
      //console.log("Grade");
      //console.log(response);
      this.dataService.getModerator(this.moderator_id).subscribe(responseModerator => {
        this.moderator_institution = responseModerator[1][0].moderator_institution;
        
      });

      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      if (response[1] == "no result") {
        this.dataService.showMessage('No tienes ningun curso dado de alta.\n Por favor solicite el alta de tu curso a <font color="blue"> comercial@perfumesfindecurso.es</font>\n En el email puedes indicarnos el nombre del curso tal y como deseas que aparezca tanto en tu app de coordinador como en la app de alumno. Tambien puedes solicitar el alta de más de un curso si asi lo precisas.', '', 'warning');
        this.grade = [];
      } else {
        this.grade = response[1];
      }
    });
  }

  ngOnInit(): void {
  }

  loadSellerList() {
    this.dataService.getUserGradeId(this.grade_id).subscribe(response =>{
      if (!response[0]) {
        //console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      if (response[1] == "no result") {
        this.sellerList = [];
      } else {
        this.sellerList = response[1];
      }
    });
  }

  showRegisterGrade(){
    this.grade_name = null;
    this.showRegisterCourse=true;
  }

  hideRegisterGrade(){
    this.showRegisterCourse=false;
  }

  registerGrade(){

    for (let i = 0; i < this.grade.length; i++) {
      if (this.grade[i].grade_name == this.grade_name) {

        Swal.close();
        this.dataService.showMessage('Este grupo ya pertenece al centro actual', 'Ingrese otro nombre de grupo.', 'error');
        return;

      }
    }
    
    this.dataService.regGrade(this.grade_name, this.moderator_id).subscribe(response =>{
      console.log(response);

      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.showRegisterCourse = false;
      this.dataService.showMessage('Se registro el curso satisfactoriamente.', '', 'success');

      this.loadGradeList();
    });
  }

  toggleSellerStatusEvent(seller : Seller) {
    const status = seller.seller_status == "0" ? 1 : 0;
    this.dataService.setSellerStatus(seller.seller_id, status).subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }
      if (seller.seller_status == "0") {
        let localId = this.dataService.getUserId();
        this.dataService.insertLog(null, localId, null, "Usuario habilitado: "+ seller.seller_id).subscribe(responseLog=>{
          console.log(responseLog);
        });
      }else{
        let localId = this.dataService.getUserId();
        this.dataService.insertLog(null, localId, null, "Usuario deshabilitado: "+ seller.seller_id).subscribe(responseLog=>{
          console.log(responseLog);
        });
      }

      this.loadSellerList();
    });
  }

  deleteSellerEvent(seller : Seller) {
    this.dataService.showMessage({
      title: `Esta seguro de eliminar a ${seller.seller_name} ${seller.seller_lastname}?`,
      text: "Esta accion no se puede deshacer.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.tryDeleteSeller(seller);
      }
    });
  }

  deleteGrade() {
    if (this.sellerList.length > 0) {
      this.dataService.showMessage("Error al eliminar curso", "No se puede eliminar un curso con alumnos registrados.", "error");
      return;
    }

    this.dataService.deleteGrade(parseInt(this.grade_id, 10)).subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.loadGradeList();
      this.grade_id = null;
    });
  }

  private tryDeleteSeller(seller : Seller) {
    const seller_id = seller.seller_id;
    this.dataService.getLastRequest(seller_id).subscribe(response => {
      console.log(response);
      if (!response[0]) {
        console.error(response);
      } else {
        if (response[1] != "no result") {
          this.dataService.showMessage("El alumno no puede ser eliminado", "El alumno que desea eliminar tiene pedidos tramitados.", "error");
          return;
        }else{
          this.checkDeleteAvailability(seller_id).then(result => {
            if (result) {
              this.dataService.getLastRequest(seller_id).subscribe(response => {
                if (!response[0]) {
                  console.error(response[1]);
                  Swal.close();
                  this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
                  return;
                }
      
                if (response[1] == "no result") {
                  this.deleteSeller(seller_id);
                } else {
                  const request = response[1][0];
                  this.deleteLastSellerRequest(request.request_id, seller_id);
                }
              });
            } else {
              this.dataService.showMessage("El alumno no puede ser eliminado", "El alumno que desea eliminar tiene pedidos tramitados.", "error");
            }
          });
        }
      }

    });
    /*this.checkDeleteAvailability(seller_id).then(result => {
      if (result) {
        this.dataService.getLastRequest(seller_id).subscribe(response => {
          if (!response[0]) {
            console.error(response[1]);
            Swal.close();
            this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
            return;
          }

          if (response[1] == "no result") {
            this.deleteSeller(seller_id);
          } else {
            const request = response[1][0];
            this.deleteLastSellerRequest(request.request_id, seller_id);
          }
        });
      } else {
        this.dataService.showMessage("El alumno no puede ser eliminado", "El alumno que desea eliminar tiene pedidos tramitados.", "error");
      }
    });*/
  }

  private checkDeleteAvailability(seller_id : number) : Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.dataService.getRequestUser(seller_id).subscribe(response => {
        if (!response[0]) {
          console.error(response[1]);
          Swal.close();
          this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
          return;
        }

        if (Array.isArray(response[1]) &&
          response[1].filter(item => item.request_date != null).length > 0) {
          resolve(false);
          return;
        }
        resolve(true);
      });
    });

  }

  private deleteLastSellerRequest(request_id : number, seller_id : number) {
    this.dataService.deleteRequest(request_id).subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.deleteSeller(seller_id);
    });
  }

  private deleteSeller(seller_id : number) {
    this.dataService.deleteUser(seller_id).subscribe(response => {

      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('No se ha podido eliminar el alumno', '', 'error');
        let localId = this.dataService.getUserId();
        this.dataService.insertLog(null, localId, null, "Error al eliminar usuario").subscribe(responseLog=>{
          console.log(responseLog);
        });
        return;
      }

      if (response[0] == true) {
        this.dataService.showMessage('Usuario eliminado', '', 'success');
        let localId = this.dataService.getUserId();
        this.dataService.insertLog(null, localId, null, "Usuario eliminado").subscribe(responseLog=>{
          console.log(responseLog);
        });
        this.loadSellerList();
      }
    });
  }

}
