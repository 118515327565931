
<mat-sidenav-container class="h-100 side-nav-desktop" >
  <mat-sidenav  #sidenav
                [mode]="sideNavMode"
                opened
                class="sidenav w-10 sidenav-custom"   *ngIf="dataService.getUserType() != ''">
    
    <div class=" text-center mt-2 mb-2">
      <img  src="./assets/logo1.png" class="w-100">
    </div>
    <div class="user-panel pt-3 pb-3">
      <div class="image align-top">
        <img *ngIf="dataService.getUserType() == typeUser" src="assets/user-avatar.png" alt="User Image">
        <img *ngIf="dataService.getUserType() == typeMod" src="assets/mod-avatar.png" alt="User Image">
      </div>
      <div class="info mb-3 user-name-text">
        <a class="d-block text-white">{{ name }}</a>
        <a class="d-block text-white">{{ moderator_id }}</a>
        <a class="d-block text-white">{{ moderator_institution }}</a>
      </div>
    </div>
    <!--User-->
    <mat-nav-list *ngIf="dataService.getUserType() == typeUser">
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeUser, 'home']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-home"></i>
        <span class="ml-2">
          Inicio
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeUser, 'home']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-home"></i>
        <span class="ml-2">
          Inicio
        </span>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeUser, 'order']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fas fa-book"></i>
        <span class="ml-2">
          Realizar pedido
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeUser, 'order']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fas fa-book"></i>
        <span class="ml-2">
          Realizar pedido
        </span>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeUser, 'record']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-history"></i>
        <span class="ml-2">
          Historial
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeUser, 'record']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-history"></i>
        <span class="ml-2">
          Historial
        </span>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeUser, 'admin-password']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-user-circle"></i>
        <span class="ml-2">
          Cambiar Contraseña
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeUser, 'admin-password']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-user-circle"></i>
        <span class="ml-2">
          Cambiar Contraseña
        </span>
      </mat-list-item>
      <mat-list-item (click)="logout()" [routerLink]="['/options']">
        <i class="nav-icon fa fa-sign-out-alt" ></i>
        <span class="ml-2">
          Cerrar sesion
        </span>
      </mat-list-item>
      <br>
      <br>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeUser, 'tutorial']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-book"></i>
        <span class="ml-2">
          Manual de instrucciones
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeUser, 'tutorial']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-book"></i>
        <span class="ml-2">
          Manual de instrucciones
        </span>
      </mat-list-item>
      
      <div class="lead text-white my-3 text-center" [class.mt-2]="dataService.getUserType() == 'admin'" [class.mt-5]="dataService.getUserType() != 'admin'">
        Asistencia
      </div>
      <div class="lead text-white my-3 text-center">
        {{email}}
      </div>

    </mat-nav-list>
    <!--Moderator-->
    <mat-nav-list *ngIf="dataService.getUserType() == typeMod">
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeMod, 'home']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-home"></i>
        <span class="ml-2">
          Inicio
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeMod, 'home']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-home"></i>
        <span class="ml-2">
          Inicio
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeMod, 'admin-orders']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fas fa-book"></i>
        <span class="ml-2">
          Administrar pedidos
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeMod, 'admin-orders']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fas fa-book"></i>
        <span class="ml-2">
          Administrar pedidos
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeMod, 'shopping-history']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-history"></i>
        <span class="ml-2">
          Historial de compras
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeMod, 'shopping-history']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-history"></i>
        <span class="ml-2">
          Historial de compras
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeMod, 'admin-users']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-table"></i>
        <span class="ml-2">
          Administrar alumnos
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeMod, 'admin-users']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-table"></i>
        <span class="ml-2">
          Administrar alumnos
        </span>
      </mat-list-item>
      <!--<mat-list-item routerLinkActive="active" [routerLink]="['/' + typeMod, 'admin-order-date']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fas fa-calendar-plus"></i>
        <span class="ml-2">
          Administrar fecha de orden
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeMod, 'admin-order-date']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fas fa-calendar-plus"></i>
        <span class="ml-2">
          Administrar fecha de orden
        </span>
      </mat-list-item>-->
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeMod, 'admin-password']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-user-circle"></i>
        <span class="ml-2">
          Cambiar Contraseña
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeMod, 'admin-password']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-user-circle"></i>
        <span class="ml-2">
          Cambiar Contraseña
        </span>
      </mat-list-item>
      <mat-list-item (click)="logout()" [routerLink]="['/options']">
        <i class="nav-icon fa fa-sign-out-alt" ></i>
        <span class="ml-2">
          Cerrar sesion
        </span>
      </mat-list-item>
  <br>
  <br>
  <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeMod, 'tutorialmod']" *ngIf="sideNavMode == 'side' ">
  <i class="nav-icon fa fa-book"></i>
  <span class="ml-2">
    Manual de instrucciones
  </span>
  </mat-list-item>
  <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeMod, 'tutorialmod']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
  <i class="nav-icon fa fa-book"></i>
  <span class="ml-2">
    Manual de instrucciones
  </span>
  </mat-list-item>

      <div class="lead text-white my-3 text-center" [class.mt-2]="dataService.getUserType() == 'admin'" [class.mt-5]="dataService.getUserType() != 'admin'">
        Asistencia
      </div>
      <div class="lead text-white my-3 text-center">
        {{phoneNumber}}
      </div>
      <div class="lead text-white my-3 text-center">
        {{email}}
      </div>

    </mat-nav-list>
    <!--Admin-->
    <mat-nav-list *ngIf="dataService.getUserType() == typeAdmin">
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'home']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-home"></i>
        <span class="ml-2">
          Inicio
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'home']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-home"></i>
        <span class="ml-2">
          Inicio
        </span>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-moderators']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fas fa-book"></i>
        <span class="ml-2">
          Administrar coordinadores
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-moderators']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fas fa-book"></i>
        <span class="ml-2">
          Administrar coordinadores
        </span>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-catalogue']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-history"></i>
        <span class="ml-2">
          Administrar catálogo
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-catalogue']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-history"></i>
        <span class="ml-2">
          Administrar catalogo
        </span>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-categorys']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fas fa-boxes"></i>
        <span class="ml-2">
          Administrar categorías
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-categorys']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fas fa-boxes"></i>
        <span class="ml-2">
          Administrar categorías
        </span>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-purchases']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-table"></i>
        <span class="ml-2">
          Administrar compras
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-purchases']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-table"></i>
        <span class="ml-2">
          Administrar compras
        </span>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-contact']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-address-card"></i>
        <span class="ml-2">
          Administrar Asistencia
        </span>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-contact']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-address-card"></i>
        <span class="ml-2">
          Administrar Asistencia
        </span>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-user-details']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-users"></i>
        <span class="ml-2">
          Listado alumnos
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-user-details']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-users"></i>
        <span class="ml-2">
          Listado alumnos
        </span>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-web-status']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-globe"></i>
        <span class="ml-2">
          Administrar Estatus Web
        </span>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-web-status']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-globe"></i>
        <span class="ml-2">
          Administrar Estatus Web
        </span>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-log']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-info"></i>
        <span class="ml-2">
          Listado de eventos
        </span>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-log']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-info"></i>
        <span class="ml-2">
          Listado de eventos
        </span>
      </mat-list-item>

      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-password']" *ngIf="sideNavMode == 'side' ">
        <i class="nav-icon fa fa-user-circle"></i>
        <span class="ml-2">
          Cambiar Contraseña
        </span>
      </mat-list-item>
      <mat-list-item routerLinkActive="active" [routerLink]="['/' + typeAdmin, 'admin-password']" (click)="sidenav.toggle()" *ngIf="sideNavMode == 'over' ">
        <i class="nav-icon fa fa-user-circle"></i>
        <span class="ml-2">
          Cambiar Contraseña
        </span>
      </mat-list-item>
      <mat-list-item (click)="logout()" [routerLink]="['/options']">
        <i class="nav-icon fa fa-sign-out-alt" ></i>
        <span class="ml-2">
          Cerrar sesion
        </span>
      </mat-list-item>

      <div class="lead text-white my-3 text-center" [class.mt-2]="dataService.getUserType() == 'admin'" [class.mt-5]="dataService.getUserType() != 'admin'">
        Asistencia
      </div>
      <div class="lead text-white my-3 text-center">
        {{phoneNumber}}
      </div>
      <div class="lead text-white my-3 text-center">
        {{email}}
      </div>

    </mat-nav-list>
    
  </mat-sidenav>
  <mat-sidenav-content [class.container-fluid]="dataService.getUserType() == ''" [class.px-0]="dataService.getUserType() == ''">

    <mat-toolbar class="sticky-top mb-5 sidenav-custom" *ngIf="dataService.getUserType() != ''">
      <button  mat-icon-button  (click)="sidenav.toggle()" *ngIf="dataService.getUserType() != ''">
        <mat-icon class="text-white">menu</mat-icon>
      </button>
      <span class="text-white">PerfumesFinDeCurso</span>
    </mat-toolbar>
    
    
    <div [class.container-fluid]="dataService.getUserType() != ''">
      <router-outlet ></router-outlet>
      <footer class="proviaje-footer-home py-2" *ngIf="dataService.getUserType() != ''">
        <div class="px-3 text-center">
          <strong>Copyright © {{actualYear}} perfumesfindecurso.es</strong>
          Todos los derechos reservados.
          - E-mail: comercial@perfumesfindecurso.es
          
          <div class="text-center">
            <a 	href="assets/aviso_legal.pdf"
                target="_blank">Aviso legal</a> -
            <a 	href="assets/politica_privacidad.pdf"
                target="_blank">Terminos y condiciones</a> -
            <a 	href="assets/proteccion_datos.pdf"
                target="_blank">Proteccion de datos</a>
          </div>
        </div>
      </footer>
    </div>

    

  </mat-sidenav-content>
</mat-sidenav-container>

