
<mat-card>
  <mat-card-header>
    <mat-card-title>
<h1>Manual de instrucciones</h1> </mat-card-title>
  </mat-card-header>
  </mat-card>

      <mat-card>
        <mat-card-content class="lateral">
          <h1 style="text-align: center;"> </h1>
<p style="text-align: center;" align="center"><span style="text-decoration: underline;"><span style="font-size: medium;"><b>Perfumesfindecurso</b></span></span></p>
<p style="text-align: center;" align="center"><span style="text-decoration: underline;"><span style="font-size: medium;">Manual del usuario del coordinador</span></span></p>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">NOTA IMPORTANTE SOBRE LA VERSIÓN DE LA APLICACIÓN:</p>
<p style="font-weight: 400; text-align: justify;">Está disponible una aplicación de escritorio, que funciona a través del navegador, y también disponemos apps para móvil, tanto para Android como para IOS.</p>
<p style="font-weight: 400; text-align: justify;">En el caso de querer utilizar el dispositivo móvil, recomendamos encarecidamente que se utilice la versión de móvil pertinente, ya sea Android o IOS.</p>
<p style="font-weight: 400; text-align: justify;"><u>Desaconsejamos el uso de la versión escritorio desde un dispositivo móvil</u> ya que, aun y habiéndose previsto su uso, las condiciones de accesibilidad son menos prácticas que accediendo desde un ordenador personal.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Para participar en nuestras campañas de productos, es preciso que inicialmente el coordinador lo solicite por email. Acto seguido, <strong>se enviara un email al coordinador en el que se le facilitarán unas credenciales de acceso al sistema y también una clave privada de 7 dígitos. Esta clave privada es una clave única del coordinador.</strong></p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Una vez obtenidas las credenciales y la clave privada, nos dirigiremos a la URL de inicio de la aplicación:</p>
<p style="font-weight: 400; text-align: justify;"><a href="https://www.perfumesfindecurso.es/aplicacion/options">https://www.perfumesfindecurso.es/aplicacion/options</a></p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;">
<li style="font-weight: 400;">Login y acceso al sistema</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;"><strong>Existen 2 versiones de la aplicación:</strong></p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;">
<li style="font-weight: 400;">Escritorio o web, recomendada para utilizar des de un ordenador o portátil.<br />Es la version que nos aparece en pantalla.</li>
</ol>
<p style="text-align: justify;"> </p>
<ol style="text-align: justify;" start="2">
<li style="font-weight: 400;">Versiones móviles (Android i Ios), pera utilizar des del dispositivo móvil o tablets.<br />Para descargar las aplicaciones podemos hacer clic en los enlaces de google play o app store, según nuestro dispotivio.</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Una vez entramos a la url mencionada, o en la aplicación si lo hacemos mediante móvil, haremos clic en la opción “Inicio de Coordinador”, donde introduciremos las credenciales que nos habrá facilitado el departamento comercial de Perfumesfindecurso.</p>
<p style="font-weight: 400; text-align: justify;">Tras hacer login, vemos que nos aparece la pantalla inicio, mostrando una lista con los últimos pedidos, que bien pueden ser pedidos tramitados o estar pendientes de tramitación por parte del coordinador.</p>
<p style="font-weight: 400; text-align: justify;">Además, nos aparece el campo  <strong>“fecha del próximo pedido</strong>”, que nos permite indicar la fecha en que tenemos previsto lanzar el pedido de todos los alumnos.</p>
<p style="font-weight: 400; text-align: justify;">Esto sirve como alerta para los alumnos, que cada vez que se conecten recibirán un recordatorio para que completen su pedido individual antes de esa fecha.</p>
<p style="font-weight: 400; text-align: justify;">Es un campo opcional, pero altamente recomendable. Además, como coordinador podrá modificar esa fecha cuando lo crea oportuno.</p>
<p style="font-weight: 400; text-align: justify;">No obstante, llegada la fecha indicada, debe saber que no ocurrirá absolutamente nada, ya que se trata solo de una función con carácter informativo y de advertencia.</p>
<p style="font-weight: 400; text-align: justify;">El objetivo es asegurar que ningun alumno olvide completar su pedido antes de la fecha en que el coordinador tiene previsto tramitar los pedidos.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;"><strong><u>PRIMEROS PASOS PARA EMPEZAR UNA CAMPAÑA DE VENTA DE PRODUCTOS</u></strong></p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Antiguamente había que crear un curso, pero ahora los cursos los creamos des de Perfumesfindecurso. Por tanto, usted debe indicarnos via email, o llamando, el nombre del curso que desea que creemos para usted y sus alumnos. También puede pedir que le creemos mas de un curso, si desea gestionar mas de un grupo por separado.</p>
<p style="font-weight: 400; text-align: justify;">Lo habitual es que nos indiquen nombre como “4ESO”, “4ESOA” y “4ESOB”,etc…</p>
<p style="font-weight: 400; text-align: justify;">Una vez dado de alta el/los curso/s, recibirá un email o notificación via whatsapp conforme ya estan creados, momento en que ya podemos facilitar la clave privada de 7 digitos a todos los alumnos, con la cual podrán registrarse ellos solos. (Recordemos que esta clave privada es una clave única del coordinador, y que también se facilita junto con las credenciales de coordinador en el momento de solicitar el alta)</p>
<p style="font-weight: 400; text-align: justify;">A partir de aquí, los alumnos podrán registrarse utilizando esta clave privada de 7 dígitos. Como es una clave única del coordinador, al introducirla el sistema ya les mostrara el nombre de su centro así como el de su coordinador.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Acto seguido completaran el registro rellenando todos los campos. Ellos mismos pueden crear su propia contraseña al registrarse, e igualmente recibirán un correo de confirmación en el que también se les mostrará el usuario y contraseña. (el usuario será la dirección de correo electrónico que habrán introducido en el registro)</p>
<p style="font-weight: 400; text-align: justify;">Una vez hecho esto, el alumno podrá empezar a confeccionar su propio pedido, y su coordinador podrá ver su pedido con las últimas modificaciones (siempre que el alumno haya guardado los cambios) en el apartado “Administrar pedidos”</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;"><u>Véase Manual de instrucciones del alumno</u></p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;" start="2">
<li style="font-weight: 400;">Administrar pedidos</li>
</ol>
<p style="text-align: justify;"> </p>
<ul style="text-align: justify;">
<li style="font-weight: 400;"><u>Agregar pedidos y hacer la compra.</u></li>
</ul>
<p style="font-weight: 400; text-align: justify;">Esta es la opción del menú más utilizada en el caso del coordinador, puesto que desde ella se procede a la validación de los pedidos que han cursado los alumnos.</p>
<p style="font-weight: 400; text-align: justify;">Importante asegurarse de que los alumnos no tienen unidades de productos pendientes de agregar o añadir a su pedido. La función de “fecha del próximo pedido” le ayudará a mantener a sus alumnos informados de los días que les quedan para hacer cualquier modificación que necesiten a sus pedidos.</p>
<p style="font-weight: 400; text-align: justify;">Al entrar en esta opción de “Administrar pedidos”, aparecerá el desplegable de los cursos, donde deberá seleccionar el curso en el que desee operar. Acto seguido se le mostrará una lista con los pedidos pendientes de validación por parte del coordinador, de los alumnos que se hallan registrados en ese grupo.</p>
<p style="font-weight: 400; text-align: justify;">Cada fila es el pedido total de un alumno.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Llegada esa fecha límite acordada con sus alumnos, el coordinador podrá disponerse a realizar la compra conjunta de todos los pedidos de sus alumnos.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">El coordinador deberá agregar todos los pedidos que desee unificar en un pedido conjunto, para posteriormente tramitarlo.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Lo hará mediante el botón “agregar” disponible a la derecha de cada pedido.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Una vez agregado un pedido, el mismo se moverá de la parte inferior hacia la superior.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Cuando el coordinador haya agregado todos los pedidos, para realizar la compra definitiva y conjunta de todos esos pedidos, deberá hacer clic en el botón “Hacer compra”.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Una vez hecho clic en el botón “Hacer compra”, el pedido será enviado a Perfumesfindecurso para su tramitación en las condiciones ofertadas y no podrán deshacerse los cambios.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">No obstante, en el caso de que el coordinador sea consciente de la existencia de algún error en el pedido tramitado, lo que hará será contactar a Perfumesfindecurso para notificarlo. En este caso, enviaremos un email a <a href="mailto:pedidosweb@perfumesfindecurso.es">pedidosweb@perfumesfindecurso.es</a> informando del error para que ellos puedan corregirlo y enviar el pedido de la forma deseada.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ul style="text-align: justify;">
<li style="font-weight: 400;"><u>Editar y eliminar pedidos</u></li>
</ul>
<p style="font-weight: 400; text-align: justify;">En el caso que necesitemos modificar o incluso eliminar el pedido de un alumno, también es posible hacerlo des de la opción del menú “Administrar pedidos”.</p>
<p style="font-weight: 400; text-align: justify;">Primero de todo haremos clic en el botón “Editar pedido”, que es el botón de la derecha de los 3 que hay en cada fila o pedido.</p>
<p style="font-weight: 400; text-align: justify;">Una vez hecho clic en “Editar pedido” veremos que accedemos al pedido del alumno con las mismas opciones que tiene el en su panel de control, y las cuales nos permiten quitar productos, cambiar cantidades y, si lo deseamos, cancelar el pedido completo.      </p>
<p style="font-weight: 400; text-align: justify;">     </p>
<ol style="text-align: justify;" start="3">
<li style="font-weight: 400;">Historial de compras</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Mediante esta opción el coordinador puede acceder con todo detalle a todos los pedidos validados (que ya se han enviado a Perfumesfindecurso para su tramitación)</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Podrá imprimir informes y podrá acceder, a través de los botones “detalle” a los detalles de todos los pedidos.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Además de otras informaciones, en este informe aparecen todos los pedidos individualizados por alumno, lo cual permite imprimirlo si se desea, para entregarlo a cada alumno junto con todos los productos que ha solicitado, de forma que tanto coordinador como alumnos pueden verificar que la entrega de productos coincida con el pedido que el alumno ha realizado.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;" start="4">
<li style="font-weight: 400;">Administrar alumnos</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Esta opción, el cual la primera vez que accedemos nos sirve para dar de alta el curso, nos sirve también para gestionar los alumnos que se han registrado a dicho curso.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Podremos añadir alumnos manualmente, eliminarlos, o modificar cualquier dato del registro, incluidos las credenciales.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">También existe la opción de deshabilitar a ese alumno, de forma que no podrá realizar más cambios ni más pedidos hasta que el coordinador vuelva a habilitarlo haciendo clic en el mismo botón.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;" start="5">
<li style="font-weight: 400;">Cambio de contraseña</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Como su nombre indica, esta opción nos permite cambiar nuestra contraseña.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;" start="6">
<li style="font-weight: 400;">Cerrar sesión</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Podemos cerrar la sesión mediante este botón. Transcurridos 5 minutos de inactividad, la sesión también se cerrará automáticamente.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Para cualquier incidencia o consulta con respecto a la aplicación, pueden dirigirse a <a href="mailto:pedidosweb@perfumesfindecurso.es">pedidosweb@perfumesfindecurso.es</a>, o bien llamando al teléfono móvil 626759971.</p>
<p style="font-weight: 400;"> </p>
<p style="font-weight: 400;"> </p>
<p style="font-weight: 400;"> </p>
<p style="font-weight: 400; text-align: center;"><span style="text-decoration: underline;"><strong>Perfumesfindecurso</strong></span></p>
<p style="font-weight: 400; text-align: center;"><span style="text-decoration: underline;">Manual del usuario del alumno</span></p>
<p style="font-weight: 400;"> </p>
<p style="font-weight: 400; text-align: justify;">NOTA IMPORTANTE SOBRE LA VERSIÓN DE LA APLICACIÓN:</p>
<p style="font-weight: 400; text-align: justify;">Está disponible una aplicación de escritorio, que funciona a través del navegador, y también disponemos apps para móvil, tanto para Android como para IOS.</p>
<p style="font-weight: 400; text-align: justify;">En el caso de querer utilizar el dispositivo móvil, recomendamos encarecidamente que se utilice la versión de móvil pertinente, ya sea Android o IOS.</p>
<p style="font-weight: 400; text-align: justify;"><u>Desaconsejamos el uso de la versión escritorio desde un dispositivo móvil</u> ya que, aun y habiéndose previsto su uso, las condiciones de accesibilidad son menos prácticas que accediendo desde un ordenador personal.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Para empezar nos dirigiremos a esta URL:</p>
<p style="font-weight: 400; text-align: justify;"><a href="https://www.perfumesfindecurso.es/aplicacion/options">https://www.perfumesfindecurso.es/aplicacion/options</a></p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Aquí tendremos la posibilidad de seguir utilizando la version de escriotrio / web, o si lo preferimos podremos también descargar la version para nuestro dispositivo móvil o Tablet.</p>
<p style="font-weight: 400; text-align: justify;">Las opciones, tanto en version escritorio como en las versiones móviles, son las siguientes:</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;">
<li style="font-weight: 400;">Registro del alumno</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Lo primero que debemos hacer para empezar es registrarnos en la aplicación, con lo cual obtendremos las credenciales (usuario y contraseña) para poder acceder al sistema y crear y modificar nuestros pedidos.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Para poder registrarnos, el coordinador o tutor, nos tiene que haber facilitado una clave única de 7 dígitos.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Cuando accedemos al registro del alumno, lo primero que nos pedirá será esta clave.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Si la clave es correcta, entonces nos permitirá introducir todos los datos necesarios para el registro.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Es importante rellenar bien el formulario de registro.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Lo primero será escoger el curso de la lista desplegable, que será el curso que el coordinador haya creado previamente. Puede que nuestro coordinador haya solicitado el alta de mas de un curso, asi que debemos tener cuidado de escoger el grupo al que pertenecemos, para registrarnos en ese grupo.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Los requisitos para la contraseña son que debe contener como mínimo 1 letra mayúscula, 1 letra minúscula y 1 numero, y la longitud mínima es de 8 dígitos.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Si se desea se pueden utilizar símbolos.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Una vez aceptados los términos y condiciones, podemos hacer clic en registrar y si hemos introducido los datos de forma correcta, se creará el usuario y acto seguido se enviara un email al alumno confirmándole sus credenciales.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">A partir de aquí ya podemos acceder al panel de control del alumno, a través del menú de principal de la aplicación, ya sea de escritorio o de móvil.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;" start="2">
<li style="font-weight: 400;">Login y acceso al sistema</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Para acceder al sistema como alumno, deberemos hacer clic en la opción “Inicio alumno” del menú principal. Tras introducir nuestras credenciales accederemos a la pantalla de inicio, donde se nos muestra información general acerca de las ventas y beneficios obtenidos por nuestros pedidos ya cerrados.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Además, en la parte superior se nos mostrará en color rojo la fecha en que el coordinador tiene previsto tramitar vuestros pedidos.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Debemos tenerla en cuenta, porque llegada esa fecha, el coordinador tramitara los pedidos y ya no podréis realizar ningun cambio.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Tanto para realizar un nuevo pedido, como para modificar el pedido que tengamos en curso, accederemos a la opción “Realizar pedido” del menú.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;" start="3">
<li style="font-weight: 400;">Realizar pedido</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">En el caso de disponer ya productos en la cesta, el sistema siempre nos muestra un mensaje informativo de que hay un pedido pendiente de validación por parte del profesor.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Este mensaje es habitual y se mostrara a menudo para recordarnos que nuestro pedido sigue abierto para seguir incrementándolo y  modificándolo, pues aún no ha sido validado/cerrado por el profesor.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Podréis añadir productos, quitarlos o modificar sus cantidades. Recordad siempre guardar los cambios mediante el botón “Guardar cambios”.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">El funcionamiento habitual, es que el coordinador tramitara vuestros pedidos llegada la fecha indicada y que aparece en el apartado “inicio”, como hemos indicado más arriba.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Cuando el coordinador haya validado vuestro pedido, este ya se encontrará en proceso de preparación y envío junto con otros pedidos de otros alumnos, y veréis que vuestra cesta aparece vacía de nuevo.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">El coordinador os avisará llegado el momento para acordar la entrega de vuestros productos.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Previo acuerdo con el coordinador, también podréis empezar a confeccionar un nuevo pedido.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;" start="4">
<li style="font-weight: 400;">Historial</li>
</ol>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">En este apartado podemos consultar los últimos pedidos que han sido validados y cursados por el coordinador.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;" start="5">
<li style="font-weight: 400;">Cambio de contraseña</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Como su nombre indica, esta opción nos permite cambiar nuestra contraseña.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<ol style="text-align: justify;" start="6">
<li style="font-weight: 400;">Cerrar sesión</li>
</ol>
<p style="text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Podemos cerrar la sesión mediante este botón. Transcurridos 8 minutos de inactividad, la sesión también se cerrará automáticamente.</p>
<p style="font-weight: 400; text-align: justify;"> </p>
<p style="font-weight: 400; text-align: justify;">Para cualquier incidencia o consulta con respecto a la aplicación, podéis dirigiros a <a href="mailto:pedidosweb@perfumesfindecurso.es">pedidosweb@perfumesfindecurso.es</a>. Se intentará solucionar vuestro problema o consulta con la mayor rapidez.</p>
</mat-card-content>
  </mat-card>

