<!-- <mat-card class="w-50 mx-auto mt-5">
  <mat-card-header >
    <mat-card-title >Modificar producto</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form >
      <mat-form-field class="d-block">
        <mat-label>Nombre</mat-label>
        <input type="text" matInput [(ngModel)]="product_name"  [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>Precio costo</mat-label>
        <input type="text" matInput [(ngModel)]="product_saleprice"  [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>Precio venta</mat-label>
        <input type="text" matInput [(ngModel)]="product_sellerprice"  [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>Alerta de stock</mat-label>
        <input type="text" matInput [(ngModel)]="product_treshold"  [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <mat-form-field class="d-block">
        <mat-label>Stock</mat-label>
        <input type="text" matInput [(ngModel)]="product_quantity"  [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <div class="col-12 text-center" >
        <label>Modificar imagen</label>
        {{imageError}}
        <div class="fileInputProfileWrap ">
            <input  type="file" (change)="fileChangeEvent($event)" class="fileInputProfile">
            <div class="img-space">
                <ng-container *ngIf="isImageSaved; else elseTemplate" class="w-15">
                    <img [src]="cardImageBase64" />
                </ng-container>
                <ng-template #elseTemplate class="w-15">
                    <img [src]="product_image" class="img-responsive">
                </ng-template>
            </div>
        </div>
        <button class="ml-1 mb-3" mat-flat-button color="warn" (click)="removeImage()" *ngIf="isImageSaved">Eliminar Imagen</button>
      </div>
      <button   mat-flat-button color="primary" (click)="updateProduct()" >Modificar producto</button>
    </form>
  </mat-card-content>
</mat-card> -->

<mat-card class="mx-auto w-50 mb-5 fade-in proviaje-last-item">
  <mat-card-header>
    <mat-card-title>
      Agregar producto
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="margeninf">
    <mat-form-field class="d-block w-75 p-3 mx-auto">
      <mat-label>Nombre producto</mat-label>
      <input [(ngModel)]="product_name"
      [ngModelOptions]="{standalone: true}" matInput>
    </mat-form-field>
    <mat-form-field class="d-block col-12 col-md-8 col-lg-6 m-auto">
      <mat-label>Seleccionar categoría</mat-label>
      <mat-select required
                  [(ngModel)]="category_id"
                  name="category">
        <mat-option *ngFor="let category of categoryData"
                    [value]="category.category_id">
          {{ category.category_name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-50 p-3 mx-auto">
      <mat-label>Cantidad de producto</mat-label>
      <input [(ngModel)]="product_quantity"
      [ngModelOptions]="{standalone: true}" matInput>
    </mat-form-field>
    <mat-form-field class="w-50 p-3 mx-auto">
      <mat-label>Alerta de stock</mat-label>
      <input [(ngModel)]="product_treshold"
      [ngModelOptions]="{standalone: true}" matInput>
    </mat-form-field>
    <mat-form-field class="w-50 p-3 mx-auto">
      <mat-label>Precio</mat-label>
      <input [(ngModel)]="product_saleprice"
      [ngModelOptions]="{standalone: true}" matInput>
    </mat-form-field>
    <mat-form-field class="w-50 p-3 mx-auto">
      <mat-label>Precio de venta</mat-label>
      <input [(ngModel)]="product_sellerprice"
      [ngModelOptions]="{standalone: true}" matInput>
    </mat-form-field>
    <mat-form-field class="d-block w-75 p-3 mx-auto">
      <mat-label>Descripcion de producto</mat-label>
      <textarea matInput [(ngModel)]="product_description"
      [ngModelOptions]="{standalone: true}"></textarea>
    </mat-form-field>
    <!--<div class="col-12 text-center">
      <mat-checkbox class="example-margin" [(ngModel)]="product_validate_generate" [ngModelOptions]="{standalone: true}">Genera papeleta</mat-checkbox>
    </div>-->
    <div class="col-12 text-center" >
      <label class="mt-3">Seleccione una imagen</label>
      {{ imageError }}
      <div class="fileInputProfileWrap">
          <input  type="file" (change)="fileChangeEvent($event)" class="fileInputProfile">
          <div class="img-space">
            <img [src]="cardImageBase64" *ngIf="product_image != null" />
            <img src="./../aplicacion/assets/producto.png" class="img-responsive" *ngIf="product_image == null">
          </div>
      </div>
      <button class="ml-1 mb-3" mat-flat-button color="warn" (click)="removeImage()" *ngIf="isImageSaved">Eliminar Imagen</button>
    </div>
    <div class="text-center col-12 m">
      <button mat-flat-button color="primary" (click)="updateProduct()">Modificar producto</button>
    </div>
  </mat-card-content>
</mat-card>
