import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataMasterService } from 'src/app/services/data-master.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit {
  opciones:boolean=true;
  user="user"
  actualYear = new Date().getFullYear();
  
  constructor(public dataService : DataMasterService, private router : Router ) {
    this.dataService.setSideNav(false);
  }

  ngOnInit(): void {
    this.dataService.getWebStatus().subscribe(responseWeb =>{
      if (responseWeb[1][0].status != "0") {
        this.webNotFound();
      }
    });
  }
  webNotFound() {
    this.dataService.showMessage('Web en mantenimiento', '', 'warning');
    this.router.navigate(['/info']);
  }

}
