<div class="text-center">
  <h2 *ngIf="moderators.length < 1">No hay coordinadores cargados</h2>
  <table mat-table *ngIf="moderators.length > 0" [dataSource]="moderatorDataSource" matSort (matSortChange)="sortData($event)">

    <ng-container matColumnDef="moderator_name" sticky class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true">
      <th mat-header-cell  *matHeaderCellDef mat-sort-header="moderator_name" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Nombre de coordinador </th>
      <td mat-cell *matCellDef="let moderator" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{moderator.moderator_name}} {{moderator.moderator_lastname}} </td>
    </ng-container>

    <ng-container matColumnDef="moderator_institution"class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true">
      <th mat-header-cell  *matHeaderCellDef mat-sort-header="moderator_institution" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Nombre de centro </th>
      <td mat-cell *matCellDef="let moderator" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{moderator.moderator_institution}} </td>
    </ng-container>

    <ng-container matColumnDef="moderator_location"class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true">
      <th mat-header-cell  *matHeaderCellDef mat-sort-header="moderator_location" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Localidad </th>
      <td mat-cell *matCellDef="let moderator" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{moderator.moderator_location}} </td>
    </ng-container>

    <ng-container matColumnDef="moderator_email"class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true">
      <th mat-header-cell  *matHeaderCellDef mat-sort-header="moderator_email" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Email </th>
      <td mat-cell *matCellDef="let moderator" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{moderator.moderator_email}} </td>
    </ng-container>

    <ng-container matColumnDef="moderator_phonenumber"class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true">
      <th mat-header-cell  *matHeaderCellDef mat-sort-header="moderator_phonenumber" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Número de telefono </th>
      <td mat-cell *matCellDef="let moderator" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{moderator.moderator_phonenumber}} </td>
    </ng-container>

    <ng-container matColumnDef="moderator_id"class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true">
      <th mat-header-cell  *matHeaderCellDef mat-sort-header="moderator_id" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Codigo de coordinador </th>
      <td mat-cell *matCellDef="let moderator" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ moderator.moderator_id }} </td>
    </ng-container>

    <ng-container matColumnDef="action" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Opciones </th>
      <td mat-cell *matCellDef="let moderator" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true">
        <div class="mx-auto" [class.custom-column-px]="paddingColumnsResponsive == true">
          <button mat-icon-button color="primary" class="mr-2" [routerLink]="['/' + userType, 'admin-users', moderator.moderator_id]" matTooltip="Alumnos"><mat-icon>group</mat-icon></button>
          <button mat-icon-button color="primary" class="mr-2" [routerLink]="['/' + userType, 'mod-detail', moderator.moderator_id]" matTooltip="Modificar"><mat-icon>edit</mat-icon></button>
          <button mat-icon-button color="warn" (click)="deleteModerator(moderator.moderator_id)" matTooltip="Eliminar"><mat-icon>delete</mat-icon></button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>


  <button mat-raised-button color="primary" class="my-3" [routerLink]="['/' + userType, 'register-moderator']">Dar de alta</button>
</div>
