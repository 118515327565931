import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DataMasterService, USER_TYPE_USER, USER_TYPE_MOD, USER_TYPE_ADMIN } from './../services/data-master.service';

import Swal from 'sweetalert2';

const USER_ROUTES : string[] = [
  'user/home',
  'user/order',
  'user/record',
  'user/tutorial',
  'user/order-detail',
  'user/admin-password'
];

const MOD_ROUTES : string[] = [
  'mod/home',
  'mod/order',
  'mod/admin-orders',
  'mod/shopping-history',
  'mod/admin-users',
  'mod/admin-password',
  'mod/tutorialmod',
  'mod/order-detail',
  'mod/sales-detail',
  'mod/register-user',
  'mod/user-detail',
  'mod/admin-order-date'
  
];

const ADMIN_ROUTES : string[] = [
  'admin/home',
  'admin/order',
  'admin/admin-users',
  'admin/admin-user-details',
  'admin/admin-contact',
  'admin/user-detail',
  'admin/register-user',
  'admin/admin-moderators',
  'admin/admin-catalogue',
  'admin/admin-purchases',
  'admin/admin-access',
  'admin/admin-password',
  'admin/sales-detail',
  'admin/order-detail',
  'admin/mod-detail',
  'admin/product-detail',
  'admin/register-moderator',
  'admin/admin-categorys',
  'admin/edit-category',
  'admin/excel-dump',
  'admin/admin-web-status',
  'admin/admin-log'
];

const ACCESS_ROUTES : any = {
  user: USER_ROUTES,
  mod: MOD_ROUTES,
  admin: ADMIN_ROUTES
};

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  private userType : string;

  constructor(private dataService : DataMasterService, private router : Router) {}

  canActivate(route : ActivatedRouteSnapshot, state : RouterStateSnapshot) : Observable<boolean> | boolean {
    this.userType = route.params.type;
    const token = sessionStorage.getItem('session_token');
    const url = `${route.url[0]}/${route.url[1]}`;

    if (ACCESS_ROUTES[this.userType] == null) {
      return this.invalidateSession();
    } else if (!ACCESS_ROUTES[this.userType].includes(url)) {
      return this.accessDenied();
    } 

    if (this.userType != "admin") {
      this.dataService.getWebStatus().subscribe(responseWeb =>{
        if (responseWeb[1][0].status != "0") {
          return this.webNotFound();
        }
      });
    }
    

    return this.dataService.checkSession(token, this.userType).pipe(tap(response => {
      if (!response) {
        this.invalidateSession();
      }
    }));
  }

  private accessDenied() : boolean {
    console.error('Access denied!');
    this.router.navigate([`/${this.userType}`, 'home']);
    return false;
  }

  private invalidateSession() : boolean {
    this.dataService.showMessage('Sesion terminada', '', 'warning');
    sessionStorage.removeItem('session_token');
    this.dataService.setUserType('');
    this.router.navigate(['/options']);
    return false;
  }

  private webNotFound() : boolean {
    this.dataService.showMessage('Web en mantenimiento', '', 'warning');
    sessionStorage.removeItem('session_token');
    this.dataService.setUserType('');
    this.router.navigate(['/info']);
    return false;
  }
}
