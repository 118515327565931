<div class="row fade-in proviaje-last-item justify-content-center">


<section class="col-lg-9 col-md-12" *ngIf="userType == typeUser">
  <mat-card class="alert col-12" role="alert" *ngIf="date != undefined">
    <mat-icon class="align-middle red1 mr-3">info</mat-icon>
    <mat-card-content class="h5">El <span class="font-weight-bold h5 red1">{{date | date:'dd-MM-yyyy'}}</span> 
    es la fecha en que tu coordinador tiene previsto tramitar tu pedido. Recuerda añadir todos los productos antes de esa fecha. Muchas gracias.</mat-card-content>
  </mat-card>
</section>

<section class="col-lg-6 col-md-12" *ngIf="userType == typeUser">
  <mat-card class="col-12">
    <mat-card-header class="secondary">
      <mat-card-title>
        Total ventas
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <h1 class="card-title text-center float-none font-weight-bold">
        {{ user_profit[0] != null && user_profit[0].total_sellerprice != null ?
          (user_profit[0].total_sellerprice | currency : "EUR" : "symbol") :
          (0 | currency : "EUR" : "symbol") }}
      </h1>
    </mat-card-content>
  </mat-card>
  <div class="text-center p-5 desk-button fourth col-12">
    <button [routerLink]="['/user', 'record']" mat-flat-button color="accent" class="p-3">
      <i class="fas fa-history"></i>
      Historial
    </button>
  </div>
</section>

<section class="col-lg-6 col-md-12" *ngIf="userType == typeUser">
  <mat-card class="col-12">
    <mat-card-header>
      <mat-card-title>
        Total beneficio
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <h1 class="card-title text-center float-none font-weight-bold">
        {{ user_profit[0] != null && user_profit[0].total_sellerprice != null && user_profit[0].total_saleprice != null ?
          (user_profit[0].total_sellerprice - user_profit[0].total_saleprice | currency : "EUR" : "symbol") :
          (0 | currency : "EUR" : "symbol") }}
      </h1>
    </mat-card-content>
  </mat-card>
  <div class="text-center p-5 desk-button third col-12">
    <button [routerLink]="['/user', 'order']"
            mat-flat-button
            color="primary"
            class="p-3">
      <i class="fas fa-edit"></i>
      Hacer/Modificar pedido
    </button>
  </div>
</section>

<!--Moderator-->
<mat-card class="col-lg-6 col-md-12 col-sm-12 mx-auto mt-5 fade-in mb-3" *ngIf="userType == typeMod">
  <mat-card-header >
    <mat-card-title >Fecha del próximo pedido</mat-card-title>
  </mat-card-header>
  <mat-card-content class="p-3">
    <h2>Esta es una función opcional para informar a los alumnos de la fecha en la que tienes previsto tramitar los pedidos</h2>
    <form>

      <mat-form-field class="d-block w-75 p-3 mx-auto">
        <mat-datepicker-toggle class="icon-display" matSuffix [for]="picker" mat-icon-align="end">
          <mat-icon>calendar_today</mat-icon>
        </mat-datepicker-toggle>
        <input matInput [matDatepicker]="picker" [(ngModel)]="date" [min]="minDate"
        [ngModelOptions]="{standalone: true}" onkeydown="return false">
        <mat-hint class="text-size">Seleccionar fecha en el calendario DD/MM/YYYY</mat-hint>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      
      <button mat-flat-button color="primary" (click)="updateDate()">Guardar</button>
    </form>
  </mat-card-content>
</mat-card>
<section  class="col-12" *ngIf="userType == typeMod">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Ultimos 10 pedidos</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="table-container">
        <app-table-order  [showCourse]="true"
                        [userType]="dataService.getUserType()"
                        [requestList]="requestModList"></app-table-order>
      </div>
    </mat-card-content>
  </mat-card>
</section>

<!--Administrator-->
<section class="col-lg-4 col-md-12 mb-3" *ngIf="userType == typeAdmin">
  <mat-card>
    <mat-card-header class="secondary">
      <mat-card-title>
        Riesgo de stock
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="table-container">
        <app-table-product  [productList]="productAdminList"
                          tableConfig="stock"></app-table-product>
      </div>
    </mat-card-content>
  </mat-card>
</section>
<section  class="col-lg-8 col-md-12"
          *ngIf="userType == typeAdmin">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Compras recientes
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="table-container">
        <app-table-sales  [showInstitute]="true"
                        [showGrade]="true"
                        [userType]="typeAdmin"
                        [orderList]="orderAdminList"></app-table-sales>
      </div>
    </mat-card-content>
  </mat-card>
</section>
</div>
